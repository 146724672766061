import * as React from 'react'
import ComingSoon from '../components/coming-soon/coming-soon'

const ServicesPage = () => {
    return (
        <ComingSoon></ComingSoon>
    )
  }
export default Object.assign(ServicesPage, {
      pageTitle: 'Services'
  })